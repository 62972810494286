<template>
  <div>
    <profile/>
  </div>
</template>

<script>
import Profile from '@/components/Profile.vue'

export default {
  name: "Profile",
  components: {
    profile: Profile
  },
}
</script>

